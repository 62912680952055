@import "../../../styles/2-tools/tools.media-query.scss";

.GridColumns {
	display: grid;
	width: 100%;
	gap: var(--spacing--lg);
	margin-top: var(--spacing--lg);

	&___noTopMargin {
		margin-top: 0;
	}

	@include mq("md", max) {
		grid-template-columns: 1fr;
	}
	@include mq("md") {
		&___20x80 {
			grid-template-columns: 30% 1fr;
			@include mq("lg") {
				grid-template-columns: 20% 1fr;
			}
		}
		&___33x66 {
			&:not(.GridColumns___noGutter) {
				grid-template-columns: calc(33.3333% - var(--spacing--md)) 1fr;
			}
			&.GridColumns___noGutter {
				grid-template-columns: 33.3333% 1fr;
			}
		}
		&___40x60 {
			grid-template-columns: 40% 1fr;
		}
		&___50x50 {
			grid-template-columns: 1fr 1fr;
		}
		&___60x40 {
			grid-template-columns: 1fr 40%;
		}
		&___66x33 {
			&:not(.GridColumns___noGutter) {
				grid-template-columns: 1fr calc(33.3333% - var(--spacing--md));
			}
			&.GridColumns___noGutter {
				grid-template-columns: 1fr 33.3333%;
			}
		}
		&___80x20 {
			grid-template-columns: 1fr 30%;
			@include mq("lg") {
				grid-template-columns: 1fr 20%;
			}
		}
	}
	&___noGutter {
		gap: 0;
	}
}

.GridColumns_column {
	&___White {
		background-color: var(--color-white);
	}
	&___Primary100 {
		background-color: var(--color-primary);
		color: var(--color-white);
	}
	&___Primary80 {
		background-color: var(--color-primary--80);
		color: var(--color-white);
	}
	&___Primary60 {
		background-color: var(--color-primary--60);
		color: var(--color-white);
	}
	&___Primary40 {
		background-color: var(--color-primary--40);
	}
	&___Primary20 {
		background-color: var(--color-primary--20);
	}
	&___Sand {
		background-color: var(--color-tertiary);
	}
	&___Sand50 {
		background-color: var(--color-bg--warm);
	}
	&___Theme {
		background-color: var(--color-theme-primary);
		color: var(--color-theme-contrast);
	}
	&___Theme60 {
		background-color: var(--color-theme-primary--60);
	}
	&___Theme30 {
		background-color: var(--color-theme-primary--30);
	}
	&___noBackground {
		background-color: transparent;
	}
	padding: var(--spacing--lg);
	display: grid;
	grid-template-columns: 1fr;
	gap: var(--spacing--lg);
	min-width: fit-content;
	&___noSpacing {
		padding: 0;
	}
}
